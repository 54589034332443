.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

form {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 250px;
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.display-container {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}
